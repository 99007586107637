import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import {ReplyResponseDto, Status, Type} from "../../../dto/reply-response.dto";
import {initialData} from "../../../assets/constant";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {ServerResponseDto} from "../../../dto/server-response.dto";
import {httpClient} from "../../../util/axios";
import {Copyright} from "../../../components/copyright";

interface FeedbackProps {
}

export const Feedback: React.FC<FeedbackProps> = () => {
    const [data, setData] = useState<ReplyResponseDto[]>(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [searchNumber, setSearchNumber] = useState<string>("");
    const [searchPhone, setSearchPhone] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string>("");
    const [typeFilter, setTypeFilter] = useState<string>("");

    const navigate = useNavigate();

    // If you plan to fetch data from an API, uncomment and adjust the fetchReplies function
    const fetchReplies = async () => {
        try {
            setIsLoading(true);
            const res = await httpClient.get<ServerResponseDto<ReplyResponseDto[]>>("/appeal/");
            const response = res.data;

            if (response.msg) {
                toast.error(response.msg);
                setIsLoading(false);
                return;
            }

            if (response.data) {
                const repliesWithContacts = await Promise.all(
                    response.data.map(async (reply) => {
                        const info = await fetchContactInfoByCitizenId(reply.citizen_id);
                        if (info === null) {
                            return {...reply, contact: null, messenger: null};
                        }
                        const [contact, messenger] = info;
                        return {...reply, contact, messenger};
                    })
                );
                setData(repliesWithContacts);
            }
        } catch (error) {
            toast.error("Помилка при отриманні даних");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContactInfoByCitizenId = async (citizenId: number): Promise<[string, string] | null> => {
        try {
            const res = await httpClient.get(`/citizen/${citizenId}`);
            return [res.data.data.phone, res.data.data.messenger_name];
        } catch (error) {
            console.error(`Помилка при отриманні контакту для citizen_id: ${citizenId}`);
            return null;
        }
    };

    useEffect(() => {
        fetchReplies();
    }, []);

    const handleSearch = async () => {
        setIsLoading(true); // Вмикаємо індикатор завантаження
        try {
            const response = await httpClient.get<ServerResponseDto<ReplyResponseDto[]>>("/appeal/");
            const repliesWithContacts = await Promise.all(
                response.data.data.map(async (reply) => {
                    const info = await fetchContactInfoByCitizenId(reply.citizen_id);
                    if (info === null) {
                        return {...reply, contact: null, messenger: null};
                    }
                    const [contact, messenger] = info;
                    return {...reply, contact, messenger};
                })
            );

            let filteredData = repliesWithContacts || [];

            // Фільтрація за номером звернення
            if (searchNumber.trim()) {
                filteredData = filteredData.filter(item =>
                    String(item.id).includes(searchNumber.trim())
                );
            }

            // Фільтрація за номером телефону
            if (searchPhone.trim()) {
                filteredData = filteredData.filter(item =>
                    String(item.contact).includes(searchPhone.trim())
                );
            }

            // Фільтрація за статусом
            if (statusFilter) {
                filteredData = filteredData.filter(item =>
                    item.status === statusFilter
                );
            }

            // Фільтрація за типом звернення
            if (typeFilter) {
                filteredData = filteredData.filter(item =>
                    item.appeal_type === typeFilter
                );
            }

            // Фільтрація за датою початку
            if (startDate) {
                const start = dayjs(startDate, "YYYY-MM-DD");
                filteredData = filteredData.filter(item =>
                    dayjs(item.date).isAfter(start)
                );
            }

            // Фільтрація за датою закінчення
            if (endDate) {
                const end = dayjs(endDate, "YYYY-MM-DD").endOf("day");
                filteredData = filteredData.filter(item =>
                    dayjs(item.date).isBefore(end)
                );
            }

            // Оновлюємо дані та встановлюємо першу сторінку
            setData(filteredData);
            setPage(1);
        } catch (error) {
            console.error("Помилка при отриманні даних", error);
            toast.error("Помилка при отриманні даних");
        } finally {
            setIsLoading(false); // Вимикаємо індикатор завантаження
        }
    };
    const handleUpdate = () => {
        fetchReplies();
    };

    const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleStatusFilterChange = (event: SelectChangeEvent) => {
        setStatusFilter(event.target.value);
    };

    const handleTypeFilterChange = (event: SelectChangeEvent) => {
        setTypeFilter(event.target.value);
    };

    const paginatedData = data.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    const handleRowClick = (id: string) => {
        navigate(`/tickets/${id}`);
    };

    return (
        <Box sx={{padding: "24px"}}>
            <h2>Чат-бот Звернення</h2>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
                flexDirection={{xs: "column", sm: "row"}}
            >
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <FormControl sx={{mr: 2, minWidth: 120, mt: {xs: 2, sm: 0}}}>
                        <InputLabel>Показати</InputLabel>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            label="Показати"
                        >
                            <MenuItem value={10}>10 записів</MenuItem>
                            <MenuItem value={25}>25 записів</MenuItem>
                            <MenuItem value={50}>50 записів</MenuItem>
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        <TextField
                            label="Номер звернення"
                            variant="outlined"
                            size="small"
                            value={searchNumber}
                            onChange={(e) => setSearchNumber(e.target.value)}
                            sx={{mr: 2, mt: {xs: 2, sm: 0}}}
                        />
                        <TextField
                            label="Телефон заявника"
                            variant="outlined"
                            size="small"
                            value={searchPhone}
                            onChange={(e) => setSearchPhone(e.target.value)}
                            sx={{mr: 2, mt: {xs: 2, sm: 0}}}
                        />
                        <TextField
                            label="Дата початку"
                            type="date"
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            size="small"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            sx={{mr: 2, mt: {xs: 2, sm: 0}}}
                        />
                        <TextField
                            label="Дата закінчення"
                            type="date"
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            size="small"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            sx={{mr: 2, mt: {xs: 2, sm: 0}}}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                            sx={{mt: {xs: 2, sm: 0}}}
                        >
                            Пошук
                        </Button>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleUpdate}
                    disabled={isLoading}
                    sx={{mt: {xs: 2, sm: 0}}}
                >
                    Оновити актуальні звернення
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Дата створення</TableCell>
                            <TableCell>
                                <Box display="flex" alignItems="center">
                                    Статус
                                    <FormControl sx={{ml: 2, minWidth: 120}}>
                                        <InputLabel>Статус</InputLabel>
                                        <Select
                                            value={statusFilter}
                                            onChange={handleStatusFilterChange}
                                            label="Статус"
                                        >
                                            <MenuItem value="">Всі</MenuItem>
                                            <MenuItem value={Status.waiting}>Очікує відповідь</MenuItem>
                                            <MenuItem value={Status.inWork}>В роботі</MenuItem>
                                            <MenuItem value={Status.closed}>Закрито</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </TableCell>
                            <TableCell>Контакт</TableCell>
                            <TableCell>Повідомлення</TableCell>
                            <TableCell>
                                <Box display="flex" alignItems="center">
                                    Тип звернення
                                    <FormControl sx={{ml: 2, minWidth: 120}}>
                                        <InputLabel>Тип</InputLabel>
                                        <Select
                                            value={typeFilter}
                                            onChange={handleTypeFilterChange}
                                            label="Тип"
                                        >
                                            <MenuItem value="">Всі</MenuItem>
                                            <MenuItem value={Type.others}>Інше звернення</MenuItem>
                                            <MenuItem value={Type.complaint}>Пропозиції/Скарги</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </TableCell>
                            <TableCell>Месенджер</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    sx={{cursor: "pointer"}}
                                    onClick={() => handleRowClick(row.id)}
                                >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>
                                        {dayjs(row.date).format("DD.MM.YYYY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell>{row.status == Status.waiting ? "Очікує відповідь" : row.status == Status.inWork ? "В роботі" : "Закрито"}</TableCell>
                                    <TableCell>{row.contact}</TableCell>
                                    <TableCell>{row.message}</TableCell>
                                    <TableCell>{row.appeal_type == Type.others ? "Інше звернення" : "Пропозиції/Скарги"}</TableCell>
                                    <TableCell>{row.messenger}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Немає даних для відображення
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Pagination
                    count={Math.ceil(data.length / rowsPerPage)}
                    page={page}
                    color="primary"
                    onChange={handlePageChange}
                    siblingCount={1}
                    boundaryCount={1}
                />
            </Box>
            <Copyright sx={{pt: 4}}/>

        </Box>
    );
};