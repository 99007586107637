import {ReplyResponseDto, Status, Type} from "../dto/reply-response.dto";

export const initialData: ReplyResponseDto[] = [
    {
        id: "error",
        date: "error",
        status: Status.closed,
        contact: "error",
        messenger: "error",
        message: "error",
        appeal_type: Type.others,
        citizen_id: 0,
    },
];
