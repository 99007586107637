import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useAuth} from "../../sign-in/providers/auth-provider";

export const ProtectedComponent = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/auth");
    }
  }, [isAuthenticated, navigate]);

  // Always return something, even if it's null
  if (!isAuthenticated) return null;

  return <>{children}</>;
};
