import React from "react";
import {Box, Paper} from "@mui/material";
import {TicketText} from "./ticket-text";
import Typography from "@mui/material/Typography";
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Avatar from "@mui/material/Avatar";
import {green} from "@mui/material/colors";

interface MessageProps {
    text: string;
    date: string;
    isAdmin: boolean;
}

export const Message: React.FC<MessageProps> = ({
                                                    text, date, isAdmin
                                                }) => {
    return (
        <Paper sx={{
            padding: '16px',
            marginBottom: '12px',
            backgroundColor: '#fffdfd',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Avatar sx={{bgcolor: "#3d553d", marginRight: "5px"}}>
                    {
                        isAdmin ? <ManageAccountsIcon/> : <PersonIcon/>
                    }
                </Avatar>
                <TicketText text={text}/>
            </Box>
            <Typography variant="body2" align="right">
                {isAdmin ? `Відповідь: ${date}` : date}
            </Typography>
        </Paper>
    );
}