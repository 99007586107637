import {DialogActions, DialogContent, DialogTitle} from "@mui/joy";
import {Dialog, DialogContentText} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

interface DeleteDialogProps {
    openDeleteDialog: boolean;
    handleDeleteCancel: () => void;
    handleDeleteConfirm: () => void;

}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
                                                              openDeleteDialog, handleDeleteCancel, handleDeleteConfirm
                                                          }) => {
    return (
        <Dialog
            open={openDeleteDialog}
            onClose={handleDeleteCancel}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            <DialogTitle id="delete-dialog-title">Підтвердити видалення</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    Ви впевнені, що хочете видалити цей елемент? Ця дія не може бути скасована.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteCancel} color="primary">
                    Скасувати
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Видалити
                </Button>
            </DialogActions>
        </Dialog>
    )
}