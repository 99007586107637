import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {httpClient} from '../../../util/axios';
import {ServerResponseDto} from '../../../dto/server-response.dto';
import {AuthResponseDto} from '../../../dto/auth-response.dto';
import {useAuth} from '../providers/auth-provider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Copyright} from '../../../components/copyright';
import SignFoto from '../../../assets/sign-in.png';
import {useState} from 'react';

const defaultTheme = createTheme();

export default function SignIn() {
    const {login} = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // Prevents the default form submission behavior

        const values = {email: email, password: password};

        httpClient
            .post<ServerResponseDto<AuthResponseDto>>('/auth/login', values)
            .then((res) => login(res.data))
            .catch((err) => {
                console.error('Sign in failed', err);
            });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${SignFoto})`,
                        backgroundColor: (t) =>
                            t.palette.mode === 'light'
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                    }}
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, backgroundColor: '#3d553d'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={onSubmit} // Pass the function reference here
                            noValidate
                            sx={{mt: 1}}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                autoComplete="username"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField margin="normal"
                                       required
                                       fullWidth
                                       label="Password"
                                       type="password"
                                       id="password"
                                       autoComplete="current-password"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign In
                            </Button>
                            <Copyright sx={{mt: 5}}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
