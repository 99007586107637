export type UserResponseDto = {
  id: number;
  email: string;
  username: string;
  password: string;
  role: string;
};

export enum Roles {
  admin = "ADMIN",
  superAdmin = "SUPERADMIN",
}