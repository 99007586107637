import React from 'react';
import './App.css';
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {CssVarsProvider as JoyCssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {Routes, Route, BrowserRouter,} from "react-router-dom";
import SignIn from "./features/sign-in/pages/sign-in";
import {Toaster} from "react-hot-toast";
import {ThemeProvider} from "@mui/material/styles";
import {AuthProvider} from "./features/sign-in/providers/auth-provider";
import {useTheme} from "@mui/material";
import HomePage from "./features/home/page/home-page";
import {Feedback} from "./features/feedback/page/feedback";
import Settings from "./features/settings/page/settings";
import {MessagePage} from "./features/message/page/message-page";
import RoleBasedRoute from "./components/role-base-route";
import {Roles} from "./dto/user-response.dto";

function App() {
    const theme = useTheme();
    const materialTheme = materialExtendTheme();

    return (
        <MaterialCssVarsProvider theme={{[MATERIAL_THEME_ID]: materialTheme}}>
            <JoyCssVarsProvider>
                <CssBaseline enableColorScheme/>
                <ThemeProvider theme={theme}>
                    <Toaster/>
                    <BrowserRouter>
                        <AuthProvider>
                            <HomePage/>
                            <Routes>
                                <Route path={"/"} element={<Feedback/>}/>
                                <Route path={"/settings"} element={<RoleBasedRoute element={Settings} allowedRoles={[Roles.superAdmin, Roles.admin]} />}/>
                                <Route path={"/auth"} element={<SignIn/>}/>
                                <Route path="/tickets/:id" element={<MessagePage/>}/>


                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
    );
}

export default App;
