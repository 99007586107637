import React from 'react';
import {Box, Button, Grid, Paper, TextField, IconButton} from '@mui/material';
import {FormatBold, FormatItalic, FormatStrikethrough, FormatUnderlined, InsertLink} from '@mui/icons-material';
import {ItemResponseDto} from '../../../dto/item-response.dto';
import {Switch, Typography} from "@mui/joy";
import axios from "axios";

interface PaperContentProps {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    canReply: boolean;
    setCanReply: React.Dispatch<React.SetStateAction<boolean>>
    contentEditableRef: React.RefObject<HTMLDivElement>;
    handleSubmit: () => void;
    handleAddChild: () => void;
    handleDeleteClick: (node: ItemResponseDto) => void;
    applyStyle: (style: string) => void;
}

export const SettingsContent: React.FC<PaperContentProps> = ({
                                                                 name,
                                                                 setName,
                                                                 canReply,
                                                                 setCanReply,
                                                                 contentEditableRef,
                                                                 handleSubmit,
                                                                 handleAddChild,
                                                                 handleDeleteClick,
                                                                 applyStyle,
                                                             }) => {
    const [imageFile, setImageFile] = React.useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = React.useState<string | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImageFile(e.target.files[0]);
        }
    };

    const handleImageUpload = async () => {
        if (!imageFile) {
            setUploadStatus('Please select an image first.');
            return;
        }

        const fileName = imageFile.name;
        const fileSize = imageFile.size;
        const fileType = imageFile.type;

        // JSON payload as per the API documentation
        const payload = {
            files: [{name: fileName, size: fileSize, type: fileType}],
            acl: 'public-read',
            contentDisposition: 'inline',
        };

        const options = {
            method: 'POST',
            url: 'https://api.uploadthing.com/v6/uploadFiles',
            headers: {
                'Content-Type': 'application/json', // Set Content-Type to application/json
                'X-Uploadthing-Api-Key': 'sk_live_b4e50478d4c465cf0a8361a97f742caaceef797b13244fb5f026c48436274fee',     // Replace with your actual API key
            },
            data: payload,
        };

        try {
            const response = await axios.request(options);
            if (response.status === 200) {
                const {url: presignedUrl, fields} = response.data.data[0];
                await uploadImageToPresignedUrl(presignedUrl, fields, imageFile);
            } else {
                console.error("Failed to request presigned URL:", response.data);
                return null;
            }
        } catch (error) {
            console.error("Error requesting presigned URL:", error);
            return null;
        }
    };

    // Step 2: Upload the Image using the Presigned URL
    const uploadImageToPresignedUrl = async (
        presignedUrl: string,
        fields: { [key: string]: string },
        imageFile: File
    ): Promise<void> => {
        const formData = new FormData();

        // Add the required fields (from presigned URL) to the form data
        Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // Add the file to the form data
        formData.append("file", imageFile);

        try {
            const response = await axios.post(presignedUrl, formData);

            if (response.status === 204) {
                console.log("Image uploaded successfully.");
                return response.data.url
            } else {
                console.error("Error uploading image:", response.status, response.data);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };


    return (
        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    mt: '24px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '520px',
                    height: '420px',
                }}
            >
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TextField
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            label="Назва пункту"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Box
                            ref={contentEditableRef}
                            component="div"
                            contentEditable
                            suppressContentEditableWarning={true}
                            sx={{
                                minWidth: 300,
                                minHeight: 100,
                                padding: '10px',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: '4px',
                                outline: 'none',
                                mt: 2,
                            }}
                        >
                            Текст
                        </Box>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => applyStyle('bold')}>
                            <FormatBold/>
                        </IconButton>
                        <IconButton onClick={() => applyStyle('italic')}>
                            <FormatItalic/>
                        </IconButton>
                        <IconButton onClick={() => applyStyle('underline')}>
                            <FormatUnderlined/>
                        </IconButton>
                        <IconButton onClick={() => applyStyle('strikeThrough')}>
                            <FormatStrikethrough/>
                        </IconButton>
                    </Grid>
                    <Grid>
                        <Typography sx={{ml: 1}} component="label"
                                    endDecorator={<Switch checked={canReply} onChange={() => {
                                        setCanReply(!canReply)
                                    }} sx={{ml: 1}}/>}>
                            Можливість відповідати на цей блок
                        </Typography>
                    </Grid>
                    <Grid item>
                        <input
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleImageUpload}
                            sx={{mt: 1}}
                        >
                            Upload Image
                        </Button>
                        {uploadStatus && <Typography sx={{mt: 1}}>{uploadStatus}</Typography>}
                    </Grid>

                </Grid>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', justifyContent: 'start', ml: 5}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {backgroundColor: '#f0f0f0'},
                        }}
                        onClick={handleSubmit}
                    >
                        Змінити
                    </Button>
                    <Button
                        onClick={handleAddChild}
                        variant="contained"
                        size="small"
                        sx={{
                            mt: 1,
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {backgroundColor: '#f0f0f0'},
                        }}
                    >
                        Пункт
                    </Button>
                    <Button
                        onClick={handleAddChild}
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{
                            mt: 1,
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {backgroundColor: '#f0f0f0'},
                        }}
                    >
                        Підпункт
                    </Button>
                    <Button
                        onClick={() => handleDeleteClick({} as ItemResponseDto)}
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{
                            mt: 1,
                            width: '100%',
                        }}
                    >
                        Видалити
                    </Button>
                </Box>
            </Paper>
        </Grid>
    );
};
